import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["control", "controlled"];

  connect() {
    console.log('ready to hide some links!');
    this.toggle();
  }

  toggle() {
    if (this.controlTarget.checked) {
      this.controlledTarget.classList.remove('visually-hidden');
      this.controlledTarget.querySelectorAll('input').forEach((elm) => {
        elm.value = elm.dataset.value || '';
      });
    } else {
      this.controlledTarget.classList.add('visually-hidden');
      this.controlledTarget.querySelectorAll('input').forEach((elm) => {
        elm.dataset.value = elm.value;
        elm.value = '';
      });
    }
  }
}
